import React, {useState, useEffect} from "react"
import LinkFadeDown from "../TransitionLinks/LinkFadeDown"
import logo from "../../images/blokhaus-white.svg"
import * as styles from "./Nav.module.scss"

const nav_links = [
  {
    'name' : 'Home',
    'url' : '/'
  },
  {
    'name' : 'Team',
    'url' : '/team'
  },
  {
    'name' : 'Careers',
    'url' : '/careers'
  },
  {
    'name' : 'Contact',
    'url' : '/contact'
  }
];

const Nav = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const path = typeof window !== "undefined" ? window.location.pathname : null;

  useEffect(()=> {
    setMobileNav(mobileNav)
  },[mobileNav])

  useEffect(() => {
    detectHome();
    if(mobileNav) {
      setMobileNav(false);
    }
    
    return () => {
      detectHome();
    };
  }, [path])

  const detectHome = () => {
    if (window) {
      if (path === '/' || path === '/404') {
            setShowNav(false)
      } else {
        setShowNav(true)
      }
    }
  }

  return (
    <nav className={`w-full bg-black text-white z-50 relative nav ${showNav ? 'nav-show' : 'nav-hidden'}`}>
      <div className={`pl-6 md:py-0 md:pr-6 mx-auto flex items-center`} style={{ maxWidth:1600 + 'px'}}>
        <div className={`w-full md:w-6/12 justify-start`}>
          <LinkFadeDown url="/" className={`table ml-0 py-8 md:py-9`}>
            <img src={logo} alt={`Blokhaus Logo`} className={styles.logo} />
          </LinkFadeDown>
        </div>
        <button className={`toggle md:hidden ${mobileNav ? 'active' : ''}`} onClick={ () => mobileNav ? setMobileNav(false) : setMobileNav(true) }>
          <div className={`bar bar01`}></div>
          <div className={`bar bar02`}></div>
          <div className={`bar bar03`}></div>
          <div className={`bar bar04`}></div>
          <div className={`bar bar05`}></div>
          <span className={`sr-only`}>mobile toggle</span>
        </button>
        <div className={`hidden md:flex md:w-6/12 flex-col md:flex-row md:justify-end mb-9 md:mb-0 md:items-center`}>
          {nav_links.map((link, index)=> (
            <LinkFadeDown 
              url={link.url} 
              className={`block h-auto text-center font-bold md:text-xl text-base leading-5 md:leading-5 p-5 link ` + styles.links}
              key={index}
            >{link.name}</LinkFadeDown>
          ))}
        </div>
      </div>

      <div className={`absolute bg-black w-full md:hidden grid place-content-center mobileNav ${mobileNav ? 'active' : ''}`}>
        {nav_links.map((link, index)=> (
          <LinkFadeDown 
            url={link.url}
            className={`block h-auto text-center h1 p-10 mobile-links ` + styles.links}
            key={index}
          >{link.name}</LinkFadeDown>
        ))}
      </div>
    </nav>
  )
}

export default Nav
