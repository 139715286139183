import React, {useEffect, useRef, useState} from "react"
import BackToTop from "../../images/back-to-top.svg"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const BackToTopButton = () => {
  const path = typeof window !== "undefined" ? window.location.pathname : null;
  const triggerRef = useRef(null);
  const pinRef = useRef(null);
  const [showBTT, setShowBTT] = useState(false);

  useEffect(()=> {
    setShowBTT(showBTT)
  },[showBTT])

  useEffect(() => {
    let startAnim = ScrollTrigger.create({
      trigger: triggerRef.current,
      scrub: true,
      start: "bottom +=100%",
      end: "+=200%",
      onEnter: () => { setShowBTT(true) },
      onLeaveBack: () => { setShowBTT(false) }
    });

    let endAnim = ScrollTrigger.create({
      trigger: document.getElementById('footer'),
      scrub: true,
      start: 'bottom bottom',
      onEnter: () => { 
        let top = pinRef.current.getBoundingClientRect().top + window.scrollY;
        pinRef.current.style.position = 'absolute';
        pinRef.current.style.bottom = top;
      },
      onLeaveBack: () => { 
        pinRef.current.style.position = null;
        pinRef.current.style.bottom = null;
      }
    });

    return () => {
      setTimeout(()=>{
        startAnim.refresh();
        endAnim.refresh();
      }, 1000);
    };
  }, [path]);

  const scrollToTop = ()=> {
    window.scrollTo({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div
        ref={triggerRef}
        className={`
          back-to-top-trigger
          block
          right-0
          absolute
        `} 
        style={{ 
          top: 250 + 'vh',
          background: "red"
        }}
      ></div>
      <button
        onClick={scrollToTop}
        ref={pinRef}
        className={`
          back-to-top
          block
          h-14 w-14
          md:h-32 md:w-32
          rounded-full
          fixed
          right-5
          md:right-10
          z-30
          ${showBTT ? 'active' : ''}
        `}
      >
        <div className={"back-to-top-text absolute top-0 left-0 w-full h-auto"} src={BackToTop} alt="" style={{paintOrder: 'stroke'}}>
          <svg width="100%" viewBox="0 0 126 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M64.3646 7.23171C63.9822 7.21198 63.6019 7.16422 63.2265 7.08881L63.2882 5.56625C63.5408 5.60644 63.7955 5.63294 64.051 5.64564C64.4017 5.68019 64.7536 5.59164 65.0463 5.39511C65.2965 5.21163 65.4316 4.86466 65.4515 4.3542L65.6277 0L67.4669 0.0758633L67.2907 4.48652C67.2779 4.85168 67.2144 5.21329 67.1022 5.56096C66.9967 5.89319 66.824 6.20008 66.5948 6.46249C66.3517 6.73305 66.0454 6.93913 65.7034 7.06234C65.2731 7.20877 64.8177 7.26638 64.3646 7.23171Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M72.7605 8.07676C72.3939 8.0127 72.0381 7.89749 71.7035 7.73449C71.3683 7.57265 71.0688 7.34531 70.8226 7.06584C70.5665 6.7685 70.3852 6.41396 70.2941 6.03198C70.1874 5.55665 70.1838 5.0639 70.2836 4.58705L71.0252 0.382812L72.8556 0.707437L72.1157 4.91167C72.0496 5.22585 72.0925 5.55319 72.2373 5.83967C72.3782 6.11372 72.6495 6.28662 73.0512 6.35837C73.2132 6.4008 73.383 6.40405 73.5466 6.36786C73.7101 6.33166 73.8628 6.25705 73.9919 6.15019C74.225 5.92971 74.377 5.63694 74.4235 5.31922L75.1828 1.12204L77.0149 1.44666L76.2732 5.6509C76.2033 6.13273 76.0316 6.59407 75.7694 7.00409C75.5533 7.33202 75.2622 7.60359 74.9203 7.79624C74.5935 7.97516 74.234 8.08617 73.8633 8.12263C73.4953 8.1593 73.1241 8.14385 72.7605 8.07676Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M78.2097 9.08957L80.6249 2.32715L82.9979 3.17752L83.1441 6.70605L85.577 4.10023L87.8671 4.91709L85.4519 11.6777L83.7184 11.0567L85.1577 7.02714L83.1705 9.19013L81.5639 8.61498L81.4053 5.6616L79.959 9.71941L78.2097 9.08957Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M87.6909 12.7998L91.1825 6.52612L94.0012 8.09985C94.4764 8.34345 94.8787 8.70867 95.1674 9.1584C95.3951 9.53017 95.517 9.95724 95.5197 10.3934C95.5226 10.8008 95.42 11.2019 95.222 11.5578C95.0233 11.9183 94.7359 12.222 94.387 12.4399C94.013 12.6705 93.5824 12.7926 93.1432 12.7928C92.6104 12.7822 92.0896 12.6313 91.6335 12.3552L90.4408 11.6884L89.3134 13.7155L87.6909 12.7998ZM93.2031 9.51126L92.0193 8.84966L91.2265 10.2734L92.4121 10.935C92.5147 11.0034 92.6313 11.0477 92.7532 11.0648C92.8752 11.0819 92.9995 11.0713 93.1168 11.0338C93.3115 10.9666 93.4754 10.8313 93.5784 10.6527C93.6792 10.4706 93.7087 10.2574 93.6612 10.0546C93.6304 9.9365 93.574 9.82658 93.4961 9.73267C93.4181 9.63877 93.3205 9.56318 93.2102 9.51126H93.2031Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M99.3688 20.5924L103.058 16.4253L101.434 14.9821L102.501 13.7771L107.141 17.8967L106.074 19.1016L104.45 17.6602L100.761 21.8274L99.3688 20.5924Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M105.94 27.4273C105.573 27.0045 105.301 26.5083 105.142 25.9718C105.007 25.5054 104.976 25.0155 105.048 24.5357C105.119 24.0767 105.276 23.6354 105.512 23.2354C105.743 22.839 106.041 22.4856 106.392 22.191C106.744 21.8975 107.145 21.6687 107.576 21.5152C108.01 21.3581 108.47 21.2856 108.931 21.3018C109.414 21.3197 109.887 21.44 110.319 21.6546C110.817 21.9123 111.254 22.2732 111.602 22.7132C111.968 23.136 112.24 23.6322 112.4 24.1687C112.534 24.635 112.568 25.1243 112.5 25.6048C112.434 26.0622 112.279 26.5022 112.044 26.8998C111.813 27.2941 111.515 27.6452 111.163 27.9372C110.811 28.2317 110.41 28.4627 109.979 28.6199C109.544 28.7816 109.081 28.8559 108.617 28.8387C108.133 28.8212 107.657 28.7009 107.222 28.4858C106.725 28.2281 106.287 27.8673 105.94 27.4273ZM107.215 26.3687C107.433 26.6511 107.736 26.8543 108.08 26.9474C108.4 27.0257 108.735 27.0153 109.049 26.9174C109.372 26.8222 109.671 26.6619 109.93 26.4463C110.187 26.2339 110.397 25.9709 110.548 25.6736C110.701 25.3828 110.771 25.0559 110.753 24.728C110.725 24.3721 110.581 24.0353 110.342 23.77C110.127 23.4903 109.825 23.2894 109.484 23.1983C109.166 23.1198 108.832 23.1289 108.519 23.2248C108.196 23.318 107.896 23.4772 107.638 23.6923C107.38 23.9065 107.168 24.1705 107.014 24.4686C106.861 24.7606 106.789 25.0884 106.806 25.4178C106.835 25.7705 106.979 26.104 107.215 26.367V26.3687Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M112.564 37.1889L117.533 34.6889L116.557 32.7483L117.995 32.0249L120.78 37.5735L119.343 38.2969L118.368 36.3562L113.399 38.8579L112.564 37.1889Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M116.128 45.9733C115.944 45.4446 115.877 44.8827 115.929 44.3255C115.977 43.8429 116.129 43.3765 116.374 42.9581C116.611 42.5583 116.921 42.2074 117.289 41.9243C117.652 41.6424 118.06 41.4257 118.497 41.2839C118.933 41.1429 119.391 41.0802 119.848 41.0986C120.309 41.1153 120.762 41.2201 121.184 41.4074C121.624 41.6055 122.018 41.8954 122.338 42.2577C122.705 42.6802 122.978 43.1765 123.138 43.7133C123.319 44.2423 123.387 44.8039 123.337 45.3611C123.287 45.8438 123.137 46.3108 122.896 46.7319C122.666 47.1321 122.358 47.4823 121.991 47.7622C121.628 48.0419 121.22 48.2563 120.784 48.3956C120.347 48.5367 119.89 48.6017 119.431 48.5879C118.967 48.5758 118.51 48.4721 118.087 48.2827C117.644 48.0841 117.248 47.793 116.926 47.4288C116.56 47.0058 116.288 46.5096 116.128 45.9733ZM117.713 45.4652C117.81 45.8084 118.016 46.1106 118.3 46.3261C118.569 46.5169 118.884 46.6315 119.213 46.6578C119.545 46.689 119.881 46.6518 120.199 46.5484C120.517 46.4477 120.81 46.2819 121.061 46.0615C121.31 45.8482 121.497 45.5715 121.603 45.2605C121.708 44.9195 121.7 44.5535 121.578 44.2178C121.481 43.8783 121.276 43.5798 120.994 43.3675C120.727 43.1762 120.413 43.0604 120.086 43.0322C119.755 43.0013 119.42 43.0385 119.103 43.1416C118.785 43.2436 118.491 43.4092 118.238 43.6286C117.986 43.8411 117.798 44.1188 117.692 44.4313C117.582 44.7678 117.586 45.1314 117.705 45.4652H117.713Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M117.815 51.714L124.901 50.6184L125.394 53.8117C125.494 54.3362 125.454 54.878 125.278 55.3819C125.127 55.7933 124.866 56.1548 124.522 56.4264C124.202 56.6786 123.822 56.8439 123.419 56.9063C123.015 56.968 122.603 56.9255 122.22 56.7828C121.808 56.6284 121.448 56.3628 121.179 56.0153C120.86 55.5879 120.659 55.0842 120.595 54.5545L120.388 53.2013L118.097 53.5541L117.815 51.714ZM123.789 54.0499L123.583 52.7073L121.973 52.9561L122.181 54.2987C122.19 54.4214 122.226 54.5406 122.287 54.6474C122.348 54.7543 122.432 54.8461 122.533 54.9162C122.705 55.0305 122.914 55.077 123.118 55.0467C123.326 55.0163 123.515 54.9086 123.647 54.745C123.72 54.647 123.771 54.5338 123.796 54.4136C123.821 54.2933 123.818 54.1691 123.789 54.0499Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M118.786 64.7996C118.809 64.4167 118.858 64.0359 118.934 63.6599L120.454 63.7305C120.408 64.0392 120.38 64.2933 120.371 64.4944C120.336 64.845 120.422 65.1973 120.616 65.4912C120.8 65.7417 121.145 65.8788 121.654 65.9023L126 66.1034L125.915 67.9435L121.515 67.7424C121.15 67.7269 120.789 67.6617 120.442 67.5483C120.111 67.4404 119.805 67.2657 119.543 67.0349C119.275 66.7893 119.072 66.4815 118.95 66.1387C118.806 65.7079 118.75 65.2525 118.786 64.7996Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M117.898 73.2082C117.964 72.8402 118.083 72.4837 118.25 72.1496C118.412 71.8162 118.64 71.5191 118.92 71.2763C119.218 71.0197 119.573 70.8382 119.956 70.747C120.432 70.6449 120.924 70.6449 121.4 70.747L125.593 71.5127L125.26 73.3458L121.065 72.5801C120.752 72.5146 120.426 72.5555 120.139 72.6965C119.864 72.8377 119.688 73.1088 119.61 73.5099C119.536 73.9104 119.61 74.2262 119.813 74.4537C120.031 74.6885 120.322 74.8426 120.639 74.8913L124.834 75.6552L124.51 77.4989L120.315 76.7349C119.834 76.6614 119.374 76.4864 118.966 76.2215C118.639 76.0044 118.369 75.7115 118.18 75.3676C118.003 75.0399 117.894 74.6799 117.859 74.3091C117.82 73.9421 117.833 73.5715 117.898 73.2082Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M116.858 78.658L123.597 81.1121L122.735 83.4832L119.212 83.6102L121.809 86.0538L120.981 88.3349L114.242 85.8791L114.873 84.1466L118.888 85.6092L116.739 83.6085L117.322 82.003L120.271 81.8583L116.23 80.3905L116.858 78.658Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M113.106 88.1372L119.351 91.6657L117.766 94.4797C117.522 94.9533 117.157 95.3539 116.709 95.6406C116.338 95.8689 115.911 95.9909 115.476 95.9935C115.068 95.9933 114.668 95.8882 114.313 95.6883C113.957 95.4868 113.658 95.197 113.446 94.8467C113.218 94.471 113.099 94.039 113.103 93.5994C113.115 93.0661 113.268 92.5456 113.547 92.0909L114.218 90.9L112.201 89.7603L113.106 88.1372ZM116.36 93.6752L117.028 92.4914L115.618 91.6904L114.953 92.8742C114.883 92.9764 114.838 93.0931 114.82 93.2153C114.803 93.3376 114.813 93.4623 114.85 93.58C114.915 93.7761 115.049 93.9418 115.227 94.0457C115.409 94.1473 115.622 94.1781 115.825 94.1322C115.942 94.1001 116.051 94.0425 116.143 93.9635C116.236 93.8845 116.31 93.7861 116.36 93.6752Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M105.261 99.7866L109.403 103.502L110.853 101.884L112.049 102.959L107.911 107.585L106.713 106.508L108.163 104.891L104.02 101.173L105.261 99.7866Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M98.4034 106.332C98.8271 105.968 99.3239 105.698 99.8603 105.542C100.326 105.409 100.816 105.379 101.294 105.453C101.752 105.528 102.192 105.688 102.591 105.925C102.986 106.159 103.337 106.461 103.628 106.816C103.919 107.17 104.145 107.572 104.298 108.005C104.452 108.44 104.521 108.901 104.502 109.363C104.481 109.846 104.356 110.319 104.136 110.75C103.879 111.247 103.519 111.683 103.079 112.029C102.655 112.394 102.158 112.664 101.622 112.821C101.156 112.953 100.667 112.985 100.188 112.915C99.7311 112.847 99.2921 112.689 98.8966 112.451C98.505 112.218 98.1563 111.92 97.8661 111.568C97.5739 111.213 97.345 110.811 97.1896 110.378C97.0312 109.941 96.9594 109.478 96.9782 109.014C97.0008 108.528 97.1257 108.052 97.3446 107.618C97.6022 107.119 97.9632 106.681 98.4034 106.332ZM99.4604 107.615C99.1763 107.83 98.9713 108.133 98.8773 108.477C98.7983 108.798 98.8068 109.134 98.9019 109.45C98.9957 109.772 99.1539 110.072 99.367 110.332C99.5785 110.59 99.8405 110.802 100.137 110.954C100.426 111.109 100.752 111.181 101.079 111.164C101.435 111.138 101.771 110.995 102.038 110.759C102.319 110.544 102.521 110.243 102.614 109.901C102.693 109.583 102.685 109.249 102.591 108.934C102.499 108.612 102.342 108.311 102.129 108.052C101.917 107.794 101.654 107.581 101.358 107.426C101.068 107.27 100.741 107.196 100.412 107.212C100.057 107.237 99.721 107.379 99.4551 107.615H99.4604Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M88.6175 112.915L91.0838 117.904L93.0217 116.939L93.7369 118.382L88.1806 121.141L87.4759 119.696L89.4138 118.731L86.9474 113.742L88.6175 112.915Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M79.8284 116.436C80.3577 116.256 80.9192 116.191 81.4755 116.245C81.9572 116.297 82.4222 116.452 82.8391 116.699C83.2366 116.939 83.5851 117.252 83.8661 117.622C84.1458 117.986 84.3598 118.397 84.4986 118.835C84.6356 119.273 84.6952 119.731 84.6747 120.189C84.6558 120.65 84.5488 121.103 84.3594 121.524C84.1592 121.965 83.8673 122.358 83.5032 122.676C83.0797 123.042 82.5829 123.312 82.0463 123.468C81.5171 123.649 80.9555 123.714 80.3992 123.659C79.9178 123.607 79.4527 123.454 79.0339 123.211C78.6354 122.977 78.287 122.667 78.0086 122.299C77.7321 121.934 77.5205 121.525 77.3832 121.088C77.2479 120.649 77.1884 120.191 77.207 119.732C77.2225 119.267 77.3284 118.81 77.5188 118.386C77.7205 117.943 78.0135 117.549 78.3785 117.228C78.8005 116.864 79.2946 116.594 79.8284 116.436ZM80.3287 118.024C79.985 118.119 79.6819 118.324 79.4655 118.608C79.2734 118.875 79.1572 119.19 79.129 119.518C79.0962 119.852 79.1316 120.189 79.2329 120.508C79.3319 120.826 79.4957 121.121 79.7139 121.372C79.9255 121.624 80.2013 121.813 80.5119 121.919C80.8511 122.027 81.2164 122.021 81.5513 121.9C81.8921 121.806 82.1924 121.602 82.4057 121.32C82.5977 121.054 82.7146 120.741 82.7439 120.414C82.7773 120.082 82.7425 119.747 82.6418 119.428C82.5407 119.109 82.3765 118.813 82.1591 118.558C81.9475 118.305 81.671 118.115 81.3593 118.008C81.0238 117.899 80.6618 117.902 80.3287 118.019V118.024Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M74.0906 118.093L75.1476 125.194L71.9572 125.67C71.4334 125.768 70.8931 125.725 70.3911 125.547C69.9816 125.393 69.6221 125.129 69.3517 124.785C69.1013 124.462 68.938 124.081 68.8778 123.677C68.8185 123.272 68.8633 122.858 69.0081 122.475C69.1644 122.065 69.4316 121.705 69.7797 121.438C70.2077 121.12 70.7123 120.921 71.2419 120.863L72.5931 120.66L72.2514 118.366L74.0906 118.093ZM71.7282 124.067L73.0705 123.865L72.8292 122.253L71.4886 122.454C71.3656 122.462 71.2461 122.498 71.139 122.56C71.032 122.621 70.9401 122.705 70.8702 122.807C70.755 122.978 70.7072 123.186 70.7363 123.391C70.7662 123.598 70.8723 123.787 71.0341 123.92C71.1318 123.994 71.2447 124.046 71.3647 124.071C71.4847 124.097 71.6088 124.095 71.7282 124.067Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M61.0136 118.999C61.3962 119.024 61.7765 119.076 62.1516 119.155L62.0741 120.677C61.8215 120.635 61.5669 120.605 61.3113 120.589C60.9613 120.552 60.6091 120.636 60.3142 120.829C60.0629 121.01 59.9243 121.356 59.8985 121.866L59.6765 126.219L57.8373 126.123L58.061 121.725C58.0785 121.359 58.1461 120.998 58.2619 120.651C58.3713 120.32 58.5469 120.015 58.778 119.754C59.0244 119.487 59.3331 119.284 59.6765 119.165C60.1064 119.02 60.5613 118.963 61.0136 118.999Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M52.6334 118.066C53.0003 118.135 53.356 118.253 53.6904 118.419C54.0224 118.584 54.3178 118.815 54.5589 119.097C54.8109 119.397 54.9873 119.753 55.0733 120.136C55.175 120.612 55.175 121.104 55.0733 121.581L54.2876 125.778L52.459 125.425L53.2447 121.228C53.3121 120.915 53.2724 120.588 53.132 120.3C52.9928 120.023 52.7233 119.848 52.3234 119.771C51.9235 119.693 51.6099 119.762 51.3809 119.968C51.1452 120.186 50.99 120.477 50.9405 120.794L50.153 124.991L48.3261 124.647L49.1118 120.452C49.1865 119.97 49.3631 119.51 49.6298 119.102C49.8491 118.777 50.1426 118.509 50.486 118.32C50.8134 118.145 51.173 118.037 51.543 118.004C51.9076 117.975 52.2745 117.996 52.6334 118.066Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M47.1899 116.997L44.706 123.738L42.3436 122.856L42.2344 119.328L39.7733 121.909L37.5008 121.067L39.9882 114.333L41.7147 114.972L40.2331 118.985L42.2432 116.844L43.8428 117.436L43.9696 120.39L45.4582 116.358L47.1899 116.997Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M37.7436 113.188L34.1868 119.423L31.3857 117.821C30.9141 117.573 30.5161 117.205 30.2318 116.753C30.0032 116.382 29.8813 115.955 29.8795 115.518C29.8813 115.11 29.9887 114.71 30.1913 114.356C30.394 114 30.6846 113.703 31.0352 113.493C31.4108 113.266 31.8421 113.149 32.2806 113.154C32.8138 113.17 33.3334 113.327 33.7869 113.608L34.9725 114.287L36.1211 112.272L37.7436 113.188ZM32.1978 116.418L33.3764 117.092L34.1833 115.681L33.0047 115.009C32.9028 114.939 32.7866 114.893 32.6646 114.874C32.5426 114.856 32.418 114.865 32.3 114.901C32.1041 114.966 31.9387 115.1 31.8349 115.279C31.7323 115.459 31.7003 115.672 31.7451 115.875C31.7761 115.993 31.8327 116.103 31.911 116.197C31.9892 116.291 32.0872 116.367 32.1978 116.418Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M26.1538 105.272L22.4209 109.402L24.031 110.859L22.9511 112.054L18.3549 107.886L19.4331 106.692L21.0433 108.151L24.7762 104.023L26.1538 105.272Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M19.6533 98.3699C20.0152 98.796 20.2819 99.2946 20.4355 99.8324C20.5659 100.3 20.5936 100.79 20.5165 101.269C20.4425 101.728 20.2816 102.168 20.0426 102.567C19.8069 102.962 19.504 103.312 19.1477 103.603C18.7924 103.892 18.3893 104.116 17.9568 104.266C17.5212 104.417 17.0609 104.484 16.6003 104.464C16.1181 104.439 15.6467 104.311 15.2174 104.09C14.7233 103.831 14.2898 103.47 13.9455 103.031C13.5836 102.604 13.3169 102.105 13.1633 101.567C13.0339 101.099 13.0045 100.61 13.077 100.131C13.1465 99.6736 13.3065 99.2352 13.5474 98.8409C13.7831 98.4487 14.0853 98.1006 14.4405 97.8124C14.797 97.5225 15.2004 97.2958 15.6332 97.1419C16.0699 96.9849 16.5332 96.9154 16.9967 96.9373C17.4814 96.9623 17.9552 97.0904 18.3867 97.3131C18.8794 97.5707 19.3113 97.931 19.6533 98.3699ZM18.3673 99.4161C18.1536 99.1307 17.8522 98.9236 17.5093 98.8268C17.1899 98.7456 16.8543 98.7529 16.5387 98.848C16.2172 98.9408 15.9176 99.0974 15.6578 99.3085C15.3992 99.5188 15.1864 99.78 15.0325 100.076C14.8772 100.365 14.803 100.692 14.8175 101.02C14.8421 101.375 14.9826 101.713 15.2174 101.981C15.4309 102.263 15.7311 102.467 16.0718 102.562C16.3893 102.643 16.7229 102.637 17.0372 102.544C17.3591 102.454 17.6589 102.298 17.918 102.087C18.1777 101.876 18.3922 101.614 18.5487 101.318C18.7048 101.027 18.7796 100.7 18.7654 100.371C18.7416 100.017 18.6016 99.6815 18.3673 99.4161Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M13.1334 88.5377L8.13726 90.9865L9.09207 92.9378L7.64751 93.6435L4.92221 88.0666L6.36677 87.3609L7.31984 89.314L12.3159 86.8669L13.1334 88.5377Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M9.66106 79.7129C9.8374 80.2444 9.89749 80.8076 9.83722 81.3643C9.78413 81.8463 9.62725 82.3109 9.37743 82.7263C9.13604 83.123 8.82229 83.4708 8.45255 83.7513C8.086 84.029 7.67413 84.241 7.23525 84.3777C6.79858 84.515 6.34127 84.5747 5.88404 84.5541C5.4237 84.5319 4.97174 84.4223 4.55221 84.2312C4.11326 84.029 3.72277 83.7348 3.40713 83.3685C3.04466 82.9419 2.77738 82.4426 2.62319 81.9042C2.44859 81.373 2.38854 80.8107 2.44702 80.2546C2.50167 79.7726 2.65649 79.3074 2.90153 78.889C3.13625 78.4911 3.44766 78.1438 3.81761 77.8675C4.1827 77.5918 4.59279 77.3817 5.02964 77.2465C5.46833 77.1101 5.92719 77.0504 6.38612 77.0701C6.84963 77.0871 7.30521 77.1956 7.72675 77.3894C8.16817 77.5924 8.56104 77.8878 8.87888 78.2557C9.24012 78.6802 9.50677 79.177 9.66106 79.7129ZM8.07555 80.2052C7.9831 79.8607 7.78006 79.5563 7.49774 79.3389C7.23292 79.141 6.91928 79.019 6.59048 78.9861C6.25706 78.9519 5.92019 78.9861 5.60042 79.0866C5.28232 79.1838 4.98779 79.346 4.73543 79.563C4.48411 79.7742 4.29374 80.049 4.18403 80.3587C4.07513 80.6985 4.08009 81.0646 4.19814 81.4013C4.29006 81.7432 4.49266 82.0447 4.77418 82.2588C5.03793 82.4536 5.34982 82.5726 5.67616 82.6028C6.00759 82.638 6.34271 82.6049 6.66095 82.5058C6.98054 82.4066 7.27662 82.2433 7.5312 82.0259C7.78512 81.8163 7.97701 81.5411 8.08613 81.2302C8.19453 80.8968 8.19205 80.5371 8.07908 80.2052H8.07555Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M8.03831 73.9578L0.942298 74.9776L0.487795 71.7807C0.393824 71.2554 0.439388 70.7145 0.61992 70.2123C0.775421 69.8021 1.04127 69.4431 1.38801 69.1749C1.71058 68.9265 2.0916 68.7655 2.49434 68.7074C2.89926 68.6496 3.31221 68.6964 3.69403 68.8432C4.10268 69.0023 4.4594 69.2716 4.72462 69.6213C5.04047 70.0516 5.23681 70.5581 5.29364 71.0891L5.48741 72.4423L7.77759 72.1142L8.03831 73.9578ZM2.08562 71.5602L2.27942 72.9046L3.88959 72.6717L3.69755 71.3273C3.68912 71.204 3.65306 71.0841 3.59207 70.9766C3.53108 70.8691 3.44671 70.7767 3.34522 70.7063C3.17413 70.5907 2.96674 70.5418 2.76212 70.5687C2.55539 70.5986 2.36708 70.7042 2.23362 70.8651C2.15959 70.963 2.10797 71.076 2.08239 71.1962C2.05682 71.3163 2.05792 71.4406 2.08562 71.5602Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M7.20171 60.8564C7.17457 61.2391 7.12041 61.6194 7.03963 61.9944L5.52107 61.9097C5.56449 61.6568 5.59507 61.4018 5.61268 61.1458C5.65153 60.7959 5.56879 60.4432 5.37839 60.1472C5.1987 59.8932 4.854 59.752 4.34429 59.7238L0 59.4786L0.103933 57.6384L4.49579 57.8854C4.86056 57.9048 5.22095 57.9743 5.56687 58.0918C5.897 58.2054 6.20039 58.3856 6.45829 58.6211C6.72447 58.8688 6.92538 59.1785 7.04316 59.5227C7.18461 59.9521 7.23853 60.4057 7.20171 60.8564Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M8.17781 52.4621C8.10695 52.826 7.98537 53.1782 7.81666 53.5083C7.65086 53.8406 7.41954 54.1359 7.13666 54.3763C6.83586 54.627 6.47943 54.8018 6.09727 54.8862C5.62029 54.9844 5.12794 54.9802 4.65271 54.8738L0.46698 54.064L0.819314 52.2345L5.00504 53.0443C5.31835 53.1143 5.64611 53.0764 5.93521 52.9367C6.21238 52.7991 6.38854 52.5303 6.46371 52.1304C6.53946 51.7299 6.47602 51.4159 6.26991 51.1848C6.0541 50.9478 5.76493 50.7906 5.44899 50.7384L1.26326 49.9304L1.61559 48.1008L5.80132 48.9089C6.28172 48.9881 6.73986 49.1685 7.14547 49.4381C7.46904 49.6593 7.73534 49.9544 7.92235 50.2991C8.09439 50.6279 8.1992 50.9878 8.23065 51.3577C8.26426 51.7265 8.24649 52.0982 8.17781 52.4621Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M9.27327 47.0264L2.56131 44.5L3.44214 42.1394L6.96548 42.0477L4.39345 39.5777L5.24609 37.3071L11.9581 39.8318L11.3098 41.559L7.31076 40.0523L9.43888 42.0777L8.83814 43.6761L5.88734 43.789L9.9057 45.301L9.27327 47.0264Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M13.128 37.5841L6.91989 33.9903L8.53535 31.1922C8.7851 30.7205 9.1553 30.3236 9.6082 30.0419C9.98031 29.8175 10.407 29.7003 10.8414 29.7032C11.2486 29.7074 11.6479 29.8168 12.0005 30.0207C12.3545 30.2259 12.65 30.5187 12.8585 30.8711C13.0832 31.249 13.198 31.6822 13.1897 32.122C13.1715 32.6549 13.0129 33.1735 12.7299 33.6251L12.0446 34.8107L14.0494 35.9716L13.128 37.5841ZM9.93058 32.0126L9.25234 33.1876L10.6617 34.0044L11.3399 32.8277C11.4104 32.726 11.4569 32.6098 11.4761 32.4876C11.4954 32.3654 11.4867 32.2404 11.4509 32.122C11.3873 31.9259 11.2545 31.7597 11.0774 31.6544C10.8976 31.55 10.6852 31.5166 10.482 31.5609C10.3626 31.591 10.2512 31.647 10.1559 31.7251C10.0606 31.8031 9.98365 31.9013 9.93058 32.0126Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M21.0995 26.021L16.9948 22.2614L15.5308 23.8651L14.3435 22.7765L18.5292 18.1895L19.7166 19.278L18.2508 20.8817L22.3538 24.6414L21.0995 26.021Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M28.0194 19.5464C27.5913 19.9063 27.0917 20.171 26.5536 20.3226C26.0858 20.4496 25.5961 20.4742 25.1179 20.395C24.6606 20.3168 24.2224 20.1521 23.8266 19.9098C23.4338 19.6716 23.0856 19.3665 22.7978 19.0083C22.5111 18.651 22.2893 18.2462 22.1424 17.8121C21.9916 17.3753 21.9264 16.9134 21.9504 16.4519C21.9778 15.969 22.1075 15.4975 22.3309 15.0687C22.5942 14.5746 22.9593 14.1422 23.402 13.8002C23.8303 13.4406 24.3298 13.176 24.8677 13.0239C25.3357 12.898 25.8247 12.8715 26.3035 12.9463C26.7588 13.0186 27.1952 13.1805 27.5877 13.4226C27.9778 13.6623 28.324 13.9673 28.6113 14.3242C28.8981 14.6825 29.122 15.0869 29.2737 15.5203C29.4281 15.9588 29.4951 16.4233 29.471 16.8876C29.4429 17.3734 29.3126 17.8478 29.0887 18.2796C28.826 18.7731 28.4615 19.2049 28.0194 19.5464ZM26.98 18.2532C27.2661 18.0404 27.4742 17.739 27.5719 17.3957C27.6547 17.0769 27.6492 16.7414 27.556 16.4254C27.4654 16.1037 27.3108 15.8036 27.1015 15.5433C26.893 15.2828 26.6334 15.0678 26.3387 14.9117C26.0506 14.7549 25.7256 14.6787 25.398 14.6911C25.0421 14.7146 24.7037 14.854 24.4344 15.0881C24.1517 15.2998 23.9462 15.5985 23.8495 15.9385C23.7669 16.2567 23.7718 16.5913 23.8636 16.907C23.9521 17.2288 24.1056 17.5291 24.3146 17.7892C24.5253 18.0498 24.7858 18.2657 25.0809 18.4243C25.3698 18.5825 25.6961 18.6593 26.0251 18.6466C26.3781 18.6249 26.7139 18.4865 26.98 18.2532Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M37.8722 13.0679L35.4552 8.0521L33.4997 8.99598L32.795 7.54576L38.3795 4.84644L39.0842 6.29666L37.1305 7.2423L39.5475 12.2581L37.8722 13.0679Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M46.6949 9.63986C46.164 9.81523 45.6019 9.87537 45.046 9.81628C44.5655 9.76012 44.1027 9.60064 43.6895 9.34875C43.2934 9.1059 42.9478 8.78905 42.6712 8.41546C42.3949 8.04792 42.185 7.63471 42.0511 7.19459C41.9174 6.7561 41.8619 6.29742 41.8873 5.83963C41.9109 5.37859 42.0227 4.92638 42.2167 4.50762C42.422 4.06916 42.718 3.67945 43.0852 3.36438C43.5136 3.0049 44.0131 2.74034 44.5509 2.5881C45.0818 2.41273 45.6439 2.35258 46.1999 2.41167C46.6806 2.46828 47.144 2.62578 47.5599 2.87391C47.9555 3.11159 48.3003 3.42515 48.5746 3.79662C48.8483 4.16373 49.0563 4.57562 49.1894 5.01396C49.3248 5.45346 49.3844 5.91288 49.3656 6.37244C49.3454 6.83698 49.2347 7.29307 49.0397 7.71505C48.831 8.15629 48.5307 8.54785 48.1588 8.86358C47.7314 9.22351 47.2324 9.48814 46.6949 9.63986ZM46.2122 8.05202C46.5566 7.96141 46.8618 7.75995 47.0807 7.47863C47.2791 7.21386 47.401 6.89953 47.433 6.57004C47.4692 6.23719 47.4374 5.90046 47.3396 5.58029C47.2435 5.26047 47.0828 4.96384 46.8675 4.70874C46.6583 4.45628 46.3851 4.26491 46.0765 4.15477C45.7389 4.04009 45.373 4.04009 45.0354 4.15477C44.6938 4.24533 44.3916 4.44626 44.1757 4.72639C43.9795 4.98972 43.8578 5.30116 43.8234 5.62792C43.7872 5.9596 43.8189 6.29519 43.9167 6.61415C44.0123 6.93777 44.1736 7.2381 44.3906 7.49628C44.5989 7.75173 44.8731 7.94516 45.1834 8.05555C45.5175 8.16708 45.8789 8.16584 46.2122 8.05202Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
            <path d="M52.4573 8.04512L51.4743 0.931615L54.67 0.488787C55.1947 0.396212 55.7346 0.444919 56.2344 0.629927C56.6431 0.787116 57.0004 1.05458 57.2667 1.40267C57.5133 1.72736 57.6722 2.11013 57.7283 2.51416C57.7827 2.91971 57.7336 3.33247 57.5856 3.71386C57.425 4.12316 57.1543 4.47983 56.8034 4.74419C56.3721 5.05728 55.8658 5.25081 55.3359 5.30522L53.9812 5.49223L54.3 7.78577L52.4573 8.04512ZM54.8832 2.09603L53.539 2.27245L53.761 3.88852L55.1051 3.70151C55.2287 3.6937 55.3489 3.65788 55.4566 3.59676C55.5643 3.53563 55.6568 3.4508 55.727 3.34866C55.8434 3.17833 55.8935 2.97124 55.8679 2.76645C55.8392 2.55946 55.7343 2.37069 55.5737 2.23717C55.4757 2.1637 55.3625 2.11293 55.2425 2.08852C55.1225 2.06412 54.9986 2.06668 54.8796 2.09603H54.8832Z" fill="black" stroke="#ffffff" strokeWidth="1px" stroke-position="outside"/>
          </svg>
        </div>
        <div className={"back-to-top-arrow absolute top-0 left-0 w-full h-auto"} src={BackToTop} alt="">
          <svg width="100%" viewBox="0 0 126 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.9725 63.2497L47.46 66.6474L61.2866 53.2014V82.5271H66.2335V53.2014L80.0354 66.6715L83.5476 63.2497L63.7601 43.9724L43.9725 63.2497Z" fill="black" stroke="#ffffff" strokeWidth="0px" stroke-position="outside"/>
          </svg>
        </div>
        <span className={'sr-only'}>Back to the top of the page.</span>
      </button>
    </>
  )
}


export default BackToTopButton
