import React from "react"
import { Helmet } from "react-helmet"
import Aurora from "../components/Aurora/Aurora"
import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"
import BackToTopButton from "../components/Buttons/BackToTopButton"

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Helmet>
      <Aurora />
      <Nav />
      <main className={`relative`} >
        {children}
        <BackToTopButton />
      </main>
      <Footer />
    </>
  )
}