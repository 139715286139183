import React from "react"
import LinkFadeDown from "../TransitionLinks/LinkFadeDown"
import logo from "../../images/blokhaus-white.svg"
import * as styles from "./Footer.module.scss"

const footer_links = [
  {
    'name' : 'Home',
    'url' : '/'
  },
  {
    'name' : 'Team',
    'url' : '/team'
  },
  {
    'name' : 'Careers',
    'url' : '/careers'
  },
  {
    'name' : 'Contact',
    'url' : '/contact'
  }
];

const Footer = () => {
  return (
    <footer className={`w-full bg-black text-white z-40 relative footer`}>
      <div className={`pt-16 pb-9 px-6 md:pt-12 md:pb-10 mx-auto md:flex align-middle`} style={{ maxWidth:1600 + 'px'}}>
        <div className={`w-full md:w-6/12`}>
          <LinkFadeDown url="/" className={`table mx-auto md:ml-0 mb-9 md:mb-2`}>
            <img src={logo} alt={`Blokhaus Logo`} className={styles.logo} />
          </LinkFadeDown>
          <span className={`text-left text-sm leading-8 hidden md:block`}>© Copyright Blokhaus Inc {(new Date().getFullYear())}</span>
        </div>
        <div className={`w-full md:w-6/12 flex-col md:flex-row md:flex md:justify-end mb-9 md:mb-0 md:items-center`}>
          {footer_links.map((link, index)=> (
            <LinkFadeDown 
              url={link.url}
              className={`block h-auto text-center font-bold md:text-xl text-base leading-5 md:leading-5 p-5 link ` + styles.links}
              key={index}
            >{link.name}</LinkFadeDown>
          ))}
        </div>
        <div className={`w-full md:hidden`}>
          <span className={`block text-center text-xs`}>© Copyright Blokhaus Inc {(new Date().getFullYear())}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
